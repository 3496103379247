import { takeLatest, call, put, all } from "redux-saga/effects";
import { toast } from "react-toastify";
import EvaluationActionTypes from "../actionTypes/evalutaion";
import {
  fetchEvaluationFailure,
  fetchEvaluationSuccess,
} from "../actions/evaluation";
import evaluation from "../../services/evaluation";

export function* fetchEvaluationStartAsync({ payload }) {
  try {
    const res = yield call(
      evaluation.getEvalutaionList,
      {
        token: payload.token,
      },
      payload.eid,
      payload.query,
      payload.limit,
      payload.skip,
      payload.filterBy
    );

    if (res.data.result !== true && res.status === 200) {
      if (res.data.result === "error") toast.error("Something went wrong");
      if (res.data.result === false) toast.error("Unable to fetch Evaluations");
      yield put(fetchEvaluationFailure());
    }

    if (res.data.result === true && res.status === 200) {
      yield put(fetchEvaluationSuccess(res.data));
    }
  } catch (e) {
    toast.error("Something went wrong");
    yield put(fetchEvaluationFailure());
  }
}

export function* fetchEvaluationStart() {
  yield takeLatest(
    EvaluationActionTypes.FETCH_EVALUATION_START,
    fetchEvaluationStartAsync
  );
}

export function* evaluationSaga() {
  yield all([call(fetchEvaluationStart)]);
}
