import ExamActionTypes from "../actionTypes/exam";
import {
  deleteSubscription,
  updateActive,
  updateFeatured,
  updateSubscription,
} from "../utils/exam";

const INITIAL_STATE = {
  examList: null,
  examDetails: null,
  examSubscriptionList: null,
  paidSubscriptionList: null,
  paidSubscriptionDetails: null,
  paidSubscriptionAllowences: null,
  progress: { show: false, percentage: 0 },
  isLoading: false,
  isRedirect: false,
};

const examReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ExamActionTypes.FETCH_EXAM_START:
    case ExamActionTypes.CREATE_EXAM_START:
    case ExamActionTypes.FETCH_SUBSCRIPTION_START:
    case ExamActionTypes.CREATE_SUBSCRIPTION_START:
    case ExamActionTypes.FETCH_EXAM_DETAILS_START:
    case ExamActionTypes.UPDATE_EXAM_PROFILE_START:
    case ExamActionTypes.UPDATE_SUBSCRIPTION_FEATURED_START:
    case ExamActionTypes.UPDATE_SUBSCRIPTION_ACTIVE_START:
    case ExamActionTypes.BUY_SUBSCRIPTION_START:
    case ExamActionTypes.FETCH_PAID_SUBSCRIPTION_LIST_START:
    case ExamActionTypes.FETCH_PAID_SUBSCRIPTION_DETAILS_START:
    case ExamActionTypes.FETCH_PAID_SUBSCRIPTION_ALLOWENCES_START:
      return {
        ...state,
        isLoading: true,
        isRedirect: false,
      };

    case ExamActionTypes.UPLOAD_QUESTION_START:
      return {
        ...state,
        isLoading: true,
        progress: { show: true, percentage: 0 },
      };

    case ExamActionTypes.FETCH_EXAM_SUCCESS:
      return {
        ...state,
        examList: action.payload,
        isLoading: false,
      };

    case ExamActionTypes.FETCH_EXAM_DETAILS_SUCCESS:
      return {
        ...state,
        examDetails: action.payload,
        isLoading: false,
      };

    case ExamActionTypes.FETCH_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        examSubscriptionList: action.payload,
        isLoading: false,
      };

    case ExamActionTypes.CREATE_EXAM_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isRedirect: true,
      };

    case ExamActionTypes.CREATE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        examDetails: action.payload,
        isRedirect: true,
      };
    case ExamActionTypes.UPDATE_SUBSCRIPTION_FEATURED_SUCCESS:
      return {
        ...state,
        examDetails: updateFeatured(state.examDetails, action.payload),
        isLoading: false,
        isRedirect: false,
      };

    case ExamActionTypes.UPDATE_SUBSCRIPTION_ACTIVE_SUCCESS:
      return {
        ...state,
        examDetails: updateActive(state.examDetails, action.payload),
        isLoading: false,
        isRedirect: false,
      };
    case ExamActionTypes.UPLOAD_QUESTION_PERCENTAGE:
      return {
        ...state,
        progress: action.payload,
      };

    case ExamActionTypes.UPLOAD_QUESTION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        progress: { show: true, percentage: 100 },
      };

    case ExamActionTypes.UPDATE_EXAM_PROFILE_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case ExamActionTypes.BUY_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isRedirect: true,
      };

    case ExamActionTypes.FETCH_PAID_SUBSCRIPTION_LIST_SUCCESS:
      return {
        ...state,
        paidSubscriptionList: action.payload,
        isLoading: false,
      };

    case ExamActionTypes.FETCH_PAID_SUBSCRIPTION_DETAILS_SUCCESS:
      return {
        ...state,
        paidSubscriptionDetails: action.payload,
        isLoading: false,
      };

    case ExamActionTypes.FETCH_PAID_SUBSCRIPTION_ALLOWENCES_SUCCESS:
      return {
        ...state,
        paidSubscriptionAllowences: action.payload,
        isLoading: false,
      };
    case ExamActionTypes.UPLOAD_QUESTION_FAILURE:
      return {
        ...state,
        isLoading: false,
        progress: { show: false, percentage: 20 },
      };

    case ExamActionTypes.CLEAR_EXAM_STATE:
      return {
        ...state,
        isRedirect: false,
      };

    case ExamActionTypes.UPDATE_EXAM_SBUSCRIPTION:
      return {
        ...state,
        examDetails: updateSubscription(state.examDetails, action.payload),
      };

    case ExamActionTypes.DELETE_EXAM_SUBSCRIPTION:
      return {
        ...state,
        examDetails: deleteSubscription(state.examDetails, action.payload),
      };

    case ExamActionTypes.MANAGER_CREATE_SUBSCRIPTION:
      return {
        ...state,
        isLoading: false,
        isRedirect: true,
      };

    case ExamActionTypes.CLEAR_SUBSCRIPTION_DETAILS:
      return {
        ...state,
        paidSubscriptionDetails: null,
        paidSubscriptionAllowences: null,
      };

    case ExamActionTypes.FETCH_EXAM_FAILURE:
    case ExamActionTypes.CREATE_EXAM_FAILURE:
    case ExamActionTypes.FETCH_SUBSCRIPTION_FAILURE:
    case ExamActionTypes.CREATE_SUBSCRIPTION_FAILURE:
    case ExamActionTypes.FETCH_EXAM_DETAILS_FAILURE:
    case ExamActionTypes.UPDATE_EXAM_PROFILE_FAILURE:
    case ExamActionTypes.UPDATE_SUBSCRIPTION_FEATURED_FAILURE:
    case ExamActionTypes.UPDATE_SUBSCRIPTION_ACTIVE_FAILURE:
    case ExamActionTypes.BUY_SUBSCRIPTION_FAILURE:
    case ExamActionTypes.FETCH_PAID_SUBSCRIPTION_LIST_FAILURE:
    case ExamActionTypes.FETCH_PAID_SUBSCRIPTION_DETAILS_FAILURE:
    case ExamActionTypes.FETCH_PAID_SUBSCRIPTION_ALLOWENCES_FAILURE:
      return {
        ...state,
        isLoading: false,
        isRedirect: false,
      };

    default:
      return state;
  }
};

export default examReducer;
