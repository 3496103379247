import TeacherActionTypes from "../actionTypes/teacher";

export const fetchTeacherStart = (token) => ({
  type: TeacherActionTypes.FETCH_TEACHER_START,
  payload: token,
});

export const fetchTeacherSuccess = (teacherDetails) => ({
  type: TeacherActionTypes.FETCH_TEACHER_SUCCESS,
  payload: teacherDetails,
});

export const fetchTeacherFailure = () => ({
  type: TeacherActionTypes.FETCH_TEACHER_FAILURE,
});

export const createTeacherStart = (teacherDetails) => ({
  type: TeacherActionTypes.CREATE_TEACHER_START,
  payload: teacherDetails,
});

export const createTeacherSuccess = () => ({
  type: TeacherActionTypes.CREATE_TEACHER_SUCCESS,
});

export const createTeacherFailure = () => ({
  type: TeacherActionTypes.CREATE_TEACHER_FAILURE,
});
