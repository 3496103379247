export const updateActive = (examDetails, sid) => {
  let index = examDetails.exam_subscription.findIndex(
    (subscription) => subscription.id == sid
  );

  examDetails.exam_subscription[index].active = !examDetails.exam_subscription[
    index
  ].active;
  return examDetails;
};

export const updateFeatured = (examDetails, sid) => {
  let index = examDetails.exam_subscription.findIndex(
    (subscription) => subscription.id == sid
  );

  examDetails.exam_subscription[index].featured = !examDetails
    .exam_subscription[index].featured;
  return examDetails;
};

export const updateSubscription = (examDetails, subscription) => {
  let filterSubscirption = examDetails.exam_subscription.filter(
    (examSub) => examSub.id !== subscription.id
  );

  examDetails.exam_subscription = [subscription, ...filterSubscirption];
  return examDetails;
};

export const deleteSubscription = (examDetails, id) => {
  let filterSubscirption = examDetails.exam_subscription.filter(
    (examSub) => examSub.id !== id
  );

  examDetails.exam_subscription = filterSubscirption;
  return examDetails;
};
