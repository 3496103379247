import {
  authApiGet,
  authApiPost,
  authApiFormDataPost,
  apiDelete,
} from "../utils/axios";
import { BASE_API_URL } from "./domain";

export default {
  getExamList: (token, isAdmin) =>
    isAdmin
      ? authApiGet(`${BASE_API_URL}/exam/get-list-all`, token)
      : authApiGet(
          `${BASE_API_URL}/exam/get-list?assign_to_manager=True`,
          token
        ),
  getExamDetails: (shortname, token) =>
    authApiGet(`${BASE_API_URL}/exam/${shortname}/details`, token),
  deleteExam: (examShortName, token) =>
    apiDelete(`${BASE_API_URL}/exam/${examShortName}/delete`, token),
  updateExamProfile: (payload, shortname, token) =>
    authApiPost(`${BASE_API_URL}/exam/${shortname}/update`, payload, token),

  uploadExamImage: (payload, shortname, token, setProgress) =>
    authApiFormDataPost(
      `${BASE_API_URL}/exam/${shortname}/upload-image`,
      payload,
      token,
      setProgress
    ),

  createExam: (payload, token) =>
    authApiPost(`${BASE_API_URL}/exam/create`, payload, token),
  getExamSubscriptionList: (examId, token) =>
    authApiGet(`${BASE_API_URL}/subscription/${examId}>/get-list"`, token),
  createExamSubscription: (payload, token) =>
    authApiPost(`${BASE_API_URL}/subscription/create`, payload, token),
  editSubscriptoin: (token, subId) =>
    authApiGet(`${BASE_API_URL}/subscription/${subId}/sub-details`, token),
  updateExamSubscriptoin: (payload, subId, token) =>
    authApiPost(
      `${BASE_API_URL}/subscription/${subId}/update-subscription`,
      payload,
      token
    ),
  deleteExamSubscription: (subId, token) =>
    apiDelete(`${BASE_API_URL}/subscription/${subId}/delete`, token),
  updateSubscriptionFeatured: (payload, token) =>
    authApiPost(`${BASE_API_URL}/subscription/update-featured`, payload, token),
  updateSubscriptionActive: (payload, token) =>
    authApiPost(`${BASE_API_URL}/subscription/update-active`, payload, token),
  buySubcription: (payload, token) =>
    authApiPost(`${BASE_API_URL}/student/add-new/subscription`, payload, token),
  getBuySubscriptionOrderId: (subId, token) =>
    authApiGet(`${BASE_API_URL}/subscription/${subId}/buy-now`, token),
  getPaidSubscriptionList: (token, query) =>
    authApiGet(
      `${BASE_API_URL}/student/all/get-subscribed${query ? query : ""}`,
      token
    ),
  getPaidSubscriptionDetails: (shortname, token) =>
    authApiGet(`${BASE_API_URL}/student/${shortname}/get-subscribed`, token),
  uploadQuestion: (payload, token, setProgress) =>
    authApiFormDataPost(
      `${BASE_API_URL}/evaluation/add-new`,
      payload,
      token,
      setProgress
    ),
  getPaidAllowences: (sid, token) =>
    authApiGet(
      `${BASE_API_URL}/subscription/get-student-subscribed-allowence-capacity?ssid=${sid}`,
      token
    ),

  getSubscriptoinCategory: (token) =>
    authApiGet(
      `${BASE_API_URL}/subscription/get-subjects-category-list`,
      token
    ),

  getSubscriptionRequest: (token) =>
    authApiGet(`${BASE_API_URL}/subscription/manager-updates`, token),

  mangerAcceptOrRejectRequest: (payload, managerId, token) =>
    authApiPost(
      `${BASE_API_URL}/manager/${managerId}/manager-requests`,
      payload,
      token
    ),

  getDashboard: (token) =>
    authApiGet(`${BASE_API_URL}/general/get-admin-dash`, token),
};
