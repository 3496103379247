import ExamActionTypes from "../actionTypes/exam";

export const fetchExamStart = (token) => ({
  type: ExamActionTypes.FETCH_EXAM_START,
  payload: token,
});

export const fetchExamSuccess = (examList) => ({
  type: ExamActionTypes.FETCH_EXAM_SUCCESS,
  payload: examList,
});

export const fetchExamFailure = () => ({
  type: ExamActionTypes.FETCH_EXAM_FAILURE,
});

export const createExamStart = (examDetails) => ({
  type: ExamActionTypes.CREATE_EXAM_START,
  payload: examDetails,
});

export const createExamSuccess = () => ({
  type: ExamActionTypes.CREATE_EXAM_SUCCESS,
});

export const createExamFailure = () => ({
  type: ExamActionTypes.CREATE_EXAM_FAILURE,
});

export const fetchExamSubscriptionStart = (tokenAndExamId) => ({
  type: ExamActionTypes.FETCH_SUBSCRIPTION_START,
  payload: tokenAndExamId,
});

export const fetchExamSubscriptionSuccess = (subscriptionList) => ({
  type: ExamActionTypes.FETCH_SUBSCRIPTION_SUCCESS,
  payload: subscriptionList,
});

export const fetchExamSubscriptionFailure = () => ({
  type: ExamActionTypes.FETCH_SUBSCRIPTION_FAILURE,
});

export const createExamSubscriptionStart = (subscriptionDetails) => ({
  type: ExamActionTypes.CREATE_SUBSCRIPTION_START,
  payload: subscriptionDetails,
});

export const managerCreateExamSubscriptionSuccess = () => ({
  type: ExamActionTypes.MANAGER_CREATE_SUBSCRIPTION,
});

export const createExamSubscriptionSuccess = (examDetails) => ({
  type: ExamActionTypes.CREATE_SUBSCRIPTION_SUCCESS,
  payload: examDetails,
});

export const createExamSubscriptionFailure = () => ({
  type: ExamActionTypes.CREATE_SUBSCRIPTION_FAILURE,
});

export const fetchExamDetailsStart = (tokenAndShortName) => ({
  type: ExamActionTypes.FETCH_EXAM_DETAILS_START,
  payload: tokenAndShortName,
});

export const fetchExamDetailsSuccess = (examDetails) => ({
  type: ExamActionTypes.FETCH_EXAM_DETAILS_SUCCESS,
  payload: examDetails,
});

export const fetchExamDetailsFailure = () => ({
  type: ExamActionTypes.FETCH_EXAM_DETAILS_FAILURE,
});

export const updateExamProfileStart = (examDetails) => ({
  type: ExamActionTypes.UPDATE_EXAM_PROFILE_START,
  payload: examDetails,
});

export const updateExamProfileSuccess = () => ({
  type: ExamActionTypes.UPDATE_EXAM_PROFILE_SUCCESS,
});

export const updateExamProfileFailure = () => ({
  type: ExamActionTypes.UPDATE_EXAM_PROFILE_FAILURE,
});

export const updateSubscriptionFeaturedStart = (featured) => ({
  type: ExamActionTypes.UPDATE_SUBSCRIPTION_FEATURED_START,
  payload: featured,
});

export const updateSubscriptionFeaturedSuccess = (sid) => ({
  type: ExamActionTypes.UPDATE_SUBSCRIPTION_FEATURED_SUCCESS,
  payload: sid,
});

export const updateSubscriptionFeaturedFailure = () => ({
  type: ExamActionTypes.UPDATE_SUBSCRIPTION_FEATURED_FAILURE,
});

export const updateSubscriptionActiveStart = (active) => ({
  type: ExamActionTypes.UPDATE_SUBSCRIPTION_ACTIVE_START,
  payload: active,
});

export const updateSubscriptionActiveSuccess = (sid) => ({
  type: ExamActionTypes.UPDATE_SUBSCRIPTION_ACTIVE_SUCCESS,
  payload: sid,
});

export const updateSubscriptionActiveFailure = () => ({
  type: ExamActionTypes.UPDATE_SUBSCRIPTION_ACTIVE_FAILURE,
});

export const buySubscriptionStart = (details) => ({
  type: ExamActionTypes.BUY_SUBSCRIPTION_START,
  payload: details,
});

export const buySubscriptionSuccess = () => ({
  type: ExamActionTypes.BUY_SUBSCRIPTION_SUCCESS,
});

export const buySubscriptionFailure = () => ({
  type: ExamActionTypes.BUY_SUBSCRIPTION_FAILURE,
});

export const fetchPaidSubscriptionListStart = (token) => ({
  type: ExamActionTypes.FETCH_PAID_SUBSCRIPTION_LIST_START,
  payload: token,
});

export const fetchPaidSubscriptionListSuccess = (paidSubscriptionList) => ({
  type: ExamActionTypes.FETCH_PAID_SUBSCRIPTION_LIST_SUCCESS,
  payload: paidSubscriptionList,
});

export const fetchPaidSubscriptionListFailure = () => ({
  type: ExamActionTypes.FETCH_PAID_SUBSCRIPTION_LIST_FAILURE,
});

export const fetchPaidSubscriptiondDetailsStart = (token) => ({
  type: ExamActionTypes.FETCH_PAID_SUBSCRIPTION_DETAILS_START,
  payload: token,
});

export const fetchPaidSubscriptiondDetailsSuccess = (subscriptionDetails) => ({
  type: ExamActionTypes.FETCH_PAID_SUBSCRIPTION_DETAILS_SUCCESS,
  payload: subscriptionDetails,
});

export const fetchPaidSubscriptiondDetailsFailure = () => ({
  type: ExamActionTypes.FETCH_PAID_SUBSCRIPTION_DETAILS_FAILURE,
});

export const uploadQuestionStart = (questionDetails) => ({
  type: ExamActionTypes.UPLOAD_QUESTION_START,
  payload: questionDetails,
});

export const uploadQuestionSuccess = () => ({
  type: ExamActionTypes.UPLOAD_QUESTION_SUCCESS,
});

export const uploadQuestionPercentage = (percentage) => ({
  type: ExamActionTypes.UPLOAD_QUESTION_PERCENTAGE,
  payload: percentage,
});

export const uploadQuestionFailure = () => ({
  type: ExamActionTypes.UPLOAD_QUESTION_FAILURE,
});

export const fetchPaidSubscriptionAllowencesStart = (sid) => ({
  type: ExamActionTypes.FETCH_PAID_SUBSCRIPTION_ALLOWENCES_START,
  payload: sid,
});

export const fetchPaidSubscriptionAllowencesSuccess = (allowences) => ({
  type: ExamActionTypes.FETCH_PAID_SUBSCRIPTION_ALLOWENCES_SUCCESS,
  payload: allowences,
});

export const fetchPaidSubscriptionAllowencesFailure = () => ({
  type: ExamActionTypes.FETCH_PAID_SUBSCRIPTION_ALLOWENCES_FAILURE,
});

export const ClearExamState = () => ({
  type: ExamActionTypes.CLEAR_EXAM_STATE,
});

export const updateExamSubscriptoin = (subscription) => ({
  type: ExamActionTypes.UPDATE_EXAM_SBUSCRIPTION,
  payload: subscription,
});

export const deleteExamSubscription = (id) => ({
  type: ExamActionTypes.DELETE_EXAM_SUBSCRIPTION,
  payload: id,
});

export const clearSubscriptionDetails = () => ({
  type: ExamActionTypes.CLEAR_SUBSCRIPTION_DETAILS,
});
