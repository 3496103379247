import React, { useEffect } from "react";
import { ToastContainer } from "react-toastify";

import "./App.css";

import Routes from "./routes/Routes";
import "react-toastify/dist/ReactToastify.css";
import { authPathCheck } from "./redux/actions/authPath";
import { connect } from "react-redux";
import { AuthPathCheck } from "./utils/utils";

function App({ authPathCheck }) {
  useEffect(() => {
    authPathCheck(AuthPathCheck(window.location.pathname));
  }, []);
  return (
    <div>
      <ToastContainer />
      <Routes />
    </div>
  );
}

const mapDispatchToProps = (dispatch) => ({
  authPathCheck: (isAuthPath) => dispatch(authPathCheck(isAuthPath)),
});

export default connect(null, mapDispatchToProps)(App);
