import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import "./style.css";
import {
  adminSidebarMenu,
  adminExamMenu,
  studentSideMenu,
  studentExamMenu,
  managerSideMenu,
  teacherSidebarMenu,
  managerExamMenu,
  ManagerExamMenuWithoutSubscription,
} from "./sidebarMenu";
import logo from "../img/logo.png";
import navBar from "../img/navBar.png";

import { createStructuredSelector } from "reselect";
import {
  selectAuthLoading,
  selectCurrentUser,
} from "../../../redux/selectors/auth";
import { connect } from "react-redux";
import { logoutStart } from "../../../redux/actions/auth";
import { sidebarOptionChange } from "../../../redux/actions/sidebar";
import {
  selectSidebarActive,
  selectSidebarOption,
  selectSidebarType,
} from "../../../redux/selectors/sidebar";
import { selectIsAuthPath } from "../../../redux/selectors/authPath";

const SidebarContainer = ({
  children,
  currentUser,
  logoutStart,
  sidebarOptionChange,
  sidebarOption,
  sidebarActive,
  sidebarType,
  isLoading,
  isAuthPath,
}) => {
  const [show, setShow] = useState(
    window.screen.availWidth <= 1200 ? false : true
  );
  const [userName, setUserName] = useState(
    currentUser
      ? `${currentUser.user.first_name} ${currentUser.user.last_name}`
      : ""
  );
  const [sidebarMenuActive, setSidebarMenuActive] = useState("dashboard");
  const [dashboardOption, setDashboardOption] = useState([]);
  let examShortName;
  let examId;
  const history = useHistory();
  const examPathCheck = ["/exam"].some((exam) =>
    history.location.pathname.includes(exam)
  );
  if (examPathCheck) {
    const examPathArray = history.location.pathname.split("/");
    examShortName = examPathArray[4];
    examId = examPathArray[5];
  }

  useEffect(() => {
    if (currentUser)
      setUserName(
        `${currentUser.user.first_name} ${currentUser.user.last_name}`
      );
  }, [isLoading]);

  useEffect(() => {
    setSidebarMenuActive(sidebarOption);
    if (sidebarActive === "main" && sidebarType === "student") {
      setDashboardOption(studentSideMenu);
    }

    if (sidebarActive === "examDetails" && sidebarType === "admin") {
      setDashboardOption(adminExamMenu);
    }

    if (sidebarActive === "main" && sidebarType === "admin") {
      setDashboardOption(adminSidebarMenu);
    }

    if (sidebarActive === "main" && sidebarType === "manager") {
      setDashboardOption(managerSideMenu);
    }
    if (sidebarActive === "examDetails" && sidebarType === "student") {
      setDashboardOption(studentExamMenu);
    }

    if (sidebarActive === "examDetails" && sidebarType === "manager") {
      if (currentUser) {
        if (!currentUser.user.can_create_subscription)
          setDashboardOption(ManagerExamMenuWithoutSubscription);
        if (currentUser.user.can_create_subscription)
          setDashboardOption(managerExamMenu);
      }
    }

    if (sidebarActive === "main" && sidebarType === "teacher") {
      setDashboardOption(teacherSidebarMenu);
    }
  }, [currentUser, sidebarOption, sidebarActive, sidebarType]);

  const handleAction = (type) => {
    if (window.screen.availWidth <= 1200) setShow(false);

    if (type === "logout") {
      logoutStart();
      history.push("/");
    }

    if (sidebarType === "student") {
      sidebarOptionChange(type, sidebarOption, "student");
      if (type === "studentHome") history.push("/student/dashboard");
      if (type === "exam") history.push("/student/exam");
      if (type === "back") history.push("/student/exam");
      if (type === "pricing")
        history.push(`/student/exam/details/${examShortName}/pricing`);
      if (type === "mysubscription") history.push("/student/my-subscription");
      if (type === "myanswer") history.push("/student/my-answer");
      if (type === "about")
        history.push(`/student/exam/details/${examShortName}/about`);
      if (type === "profile") history.push("/student/profile");
    }
    if (sidebarType === "admin") {
      sidebarOptionChange(type, sidebarOption, "admin");
      if (type === "dashboard" || type === "setting")
        history.push("/admin/dashboard");

      if (type === "evaluator") history.push("/admin/evaluator");
      if (type === "exam") {
        history.push("/admin/exam");
      }

      if (type === "manager") {
        history.push("/admin/manager");
      }

      if (type === "examProfile")
        history.push(`/admin/exam/details/${examShortName}/${examId}/profile`);

      if (type === "examSubscription")
        history.push(
          `/admin/exam/details/${examShortName}/${examId}/subscription`
        );

      if (type === "examHome")
        history.push(`/admin/exam/details/${examShortName}/${examId}`);

      if (type === "back") history.push("/admin/exam");
      if (type === "request") history.push("/admin/request");
      if (type === "profile") history.push("/admin/profile");
      if (type === "student") history.push("/admin/student/find");
    }

    if (sidebarType === "teacher") {
      sidebarOptionChange(type, sidebarOption, "teacher");

      if (type === "teacherDashboard") history.push("/evaluator/dashboard");
      if (type === "glance") history.push("/evaluator/glance");
      if (type === "summary") history.push("/evaluator/summary");
      if (type === "profile") history.push("/evaluator/profile");
    }

    if (sidebarType === "manager") {
      sidebarOptionChange(type, sidebarOption, "manager");
      if (type === "managerDashboard") history.push("/manager/dashboard");
      if (type === "exam") {
        history.push("/manager/exam");
      }

      if (type === "examHome")
        history.push(`/manager/exam/details/${examShortName}/${examId}`);

      if (type === "examSubscription")
        history.push(
          `/manager/exam/details/${examShortName}/${examId}/subscription`
        );

      if (type === "examHome")
        history.push(`/manager/exam/details/${examShortName}/${examId}`);

      if (type === "back") history.push("/manager/exam");
      if (type === "profile") history.push("/manager/profile");
    }
  };

  return currentUser && currentUser.user.isVerified && isAuthPath ? (
    <div id="app">
      {" "}
      <div id="sidebar" className={`${show ? "active" : ""} m-0`}>
        <div className=" sidebar-wrapper  m-0">
          <div className="sidebar-header pt-3">
            <div className="d-flex justify-content-between">
              <div className="logo m-0">
                <Link>
                  <img src={logo} alt="Logo" width="50px" height="50px" />
                </Link>
              </div>

              <div className="">
                <Link onClick={() => setShow(!show)}>
                  <i
                    className="fas  fa-times"
                    style={{ fontSize: "20px", color: "#87898b" }}
                  />
                </Link>
              </div>
            </div>
          </div>
          {/* <div
            className="d-flex flex-column justify-content-between"
            style={{ height: "calc(100vh  -  90px)" }}
          > */}
          <div className="sidebar-menu d-flex flex-column  justify-content-between">
            <ul className="menu m-0">
              {dashboardOption.map((menu) => (
                <li
                  className={`sidebar-item ${
                    sidebarMenuActive === menu.option ? "active" : ""
                  }`}
                  onClick={() => handleAction(menu.option)}
                  key={menu.option}
                >
                  <div className="sidebar-link">
                    <i className={`${menu.icon}`} />
                    <span>{menu.name}</span>
                  </div>
                </li>
              ))}
            </ul>

            <div className="menu">
              {currentUser.user.isAdmin ? (
                <li
                  className={`sidebar-item ${
                    sidebarMenuActive === "request" ? "active" : ""
                  }`}
                >
                  <div
                    className="sidebar-link"
                    onClick={() => handleAction("request")}
                  >
                    <i className="fas fa-lg fa-angle-double-right" />
                    <span>Request</span>
                  </div>
                </li>
              ) : (
                ""
              )}

              {currentUser.user.isStudent ? (
                <>
                  <li
                    className={`sidebar-item ${
                      sidebarMenuActive === "exam" ? "active" : ""
                    }`}
                  >
                    <div
                      className="sidebar-link"
                      onClick={() => handleAction("exam")}
                    >
                      <i className="fas fa-lg fa-book-reader" />
                      <span>Exam</span>
                    </div>
                  </li>{" "}
                </>
              ) : (
                ""
              )}
              {!currentUser.user.isAdmin ? (
                <li
                  className={`sidebar-item ${
                    sidebarMenuActive === "profile" ? "active" : ""
                  }`}
                >
                  <div
                    className="sidebar-link"
                    onClick={() => handleAction("profile")}
                  >
                    <i className="fa fa-lg fa-user-circle" />
                    <span>Profile</span>
                  </div>
                </li>
              ) : (
                ""
              )}
              <li className="sidebar-item ">
                <div
                  className="sidebar-link"
                  onClick={() => handleAction("logout")}
                >
                  <i className="fa fa-lg fa-sign-out-alt" />
                  <span>Logout</span>
                </div>
              </li>
              {currentUser.user.isStudent ? (
                <li className="sidebar-item">
                  <span className="sidebar-link">support@mainsias.com</span>
                </li>
              ) : (
                ""
              )}
            </div>
          </div>{" "}
        </div>
      </div>
      <div id="main" className="layout-navbar">
        <header className="mb-3">
          <nav className="navbar navbar-dashboard navbar-expand navbar-light ">
            <div className="container-fluid">
              <img
                className=" cursor-pointer"
                width="25px"
                src={navBar}
                alt=""
                onClick={() => setShow(!show)}
              />

              <div
                className="collapse navbar-collapse justify-content-end"
                id="navbarSupportedContent"
              >
                <div className="dropdown mr-4">
                  <div className="user-menu d-flex  ">
                    <div className="user-name text-end mr-3 mt-1">
                      <h6 className="mb-0 text-secondary">{userName}</h6>
                      <p className="mb-0 text-sm text-secondary ">
                        {currentUser.user.isAdmin
                          ? "Adminstrator"
                          : currentUser.user.isStudent
                          ? "Student"
                          : currentUser.user.isManager
                          ? "Manager"
                          : "Evaluator"}
                      </p>
                    </div>
                    {/* User Image */}
                    <div className="user-img d-flex  align-items-center">
                      <div className="avatar avatar-md">
                        <img
                          src={`https://avatars.dicebear.com/api/male/${currentUser.user.email}.svg?r=5&m=10&mood[]=happy`}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </header>
        {children}
      </div>
    </div>
  ) : (
    <> {children} </>
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  sidebarOption: selectSidebarOption,
  sidebarActive: selectSidebarActive,
  sidebarType: selectSidebarType,
  isLoading: selectAuthLoading,
  isAuthPath: selectIsAuthPath,
});

const mapDispatchToProps = (dispatch) => ({
  logoutStart: () => dispatch(logoutStart()),
  sidebarOptionChange: (selectedOption, setOptions, userType) =>
    dispatch(sidebarOptionChange({ selectedOption, setOptions, userType })),
});

export default connect(mapStateToProps, mapDispatchToProps)(SidebarContainer);
