import { createSelector } from "reselect";

const selectAuth = (state) => state.auth;

export const selectCurrentUser = createSelector(
  [selectAuth],
  (auth) => auth.currentUser
);

export const selectRedirect = createSelector(
  [selectAuth],
  (auth) => auth.isRedirect
);

export const selectAuthLoading = createSelector(
  [selectAuth],
  (auth) => auth.isLoading
);
