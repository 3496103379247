import { authApiGet, authApiPost } from "../utils/axios";
import { BASE_API_URL } from "./domain";

export default {
  getManagerList: (token) =>
    authApiGet(`${BASE_API_URL}/manager/get-list `, token),
  createManager: (payload, token) =>
    authApiPost(`${BASE_API_URL}/manager/create`, payload, token),
  getManager: (token, managerId) =>
    authApiGet(`${BASE_API_URL}/manager/${managerId}/details`, token),
  updateManagerExam: (payload, managerId, token) =>
    authApiPost(
      `${BASE_API_URL}/manager/${managerId}/update-exams?assign_to_manager=True`,
      payload,
      token
    ),
  managerActive: (payload, managerId, token) =>
    authApiPost(
      `${BASE_API_URL}/auth/${managerId}/update-active`,
      payload,
      token
    ),
  managerCanCreateSubscription: (payload, managerId, token) =>
    authApiPost(
      `${BASE_API_URL}/auth/${managerId}/update-can_create_subscription`,
      payload,
      token
    ),
};
