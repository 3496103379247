import SidebarActionTypes from "../actionTypes/sidebar";

const INITIAL_STATE = {
  sidebarOption: null,
  active: null,
  type: null,
};

const sidebarReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SidebarActionTypes.SIDEBAR_OPTION_CHANGE:
      return {
        ...state,
        sidebarOption: action.payload.selectedOption,
        active: action.payload.setOptions,
        type: action.payload.userType,
      };

    default:
      return state;
  }
};

export default sidebarReducer;
