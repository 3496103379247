import AuthPathActionTypes from "../actionTypes/authPath";

const INITIAL_STATE = {
  isAuthPath: false,
};

const authPathReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AuthPathActionTypes.AUTH_PATH_CHECK:
      return {
        isAuthPath: action.payload,
      };

    default:
      return state;
  }
};

export default authPathReducer;
