import { createSelector } from "reselect";

const selectSidebar = (state) => state.sidebar;

export const selectSidebarOption = createSelector(
  [selectSidebar],
  (sidebar) => sidebar.sidebarOption
);

export const selectSidebarActive = createSelector(
  [selectSidebar],
  (sidebar) => sidebar.active
);

export const selectSidebarType = createSelector(
  [selectSidebar],
  (sidebar) => sidebar.type
);
