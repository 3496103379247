import TeacherActionTypes from "../actionTypes/teacher";

const INITIAL_STATE = {
  teacherList: null,
  isLoading: false,
  isRedirect: false,
};

const teacherReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TeacherActionTypes.FETCH_TEACHER_START:
    case TeacherActionTypes.CREATE_TEACHER_START:
      return {
        ...state,
        isLoading: true,
        isRedirect: false,
      };

    case TeacherActionTypes.FETCH_TEACHER_SUCCESS:
      return {
        ...state,
        teacherList: action.payload,
        isLoading: false,
      };

    case TeacherActionTypes.CREATE_TEACHER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isRedirect: true,
      };

    case TeacherActionTypes.FETCH_TEACHER_FAILURE:
    case TeacherActionTypes.CREATE_TEACHER_FAILURE:
      return {
        ...state,
        isLoading: false,
      };

    default:
      return state;
  }
};

export default teacherReducer;
