export function apiDomainName() {
  if (window.location.hostname === "localhost") return "http://localhost:8000/";
  else return `https://${window.location.hostname}/api/`;
}

export function mediaDomainName() {
  if (window.location.hostname === "localhost") return "http://localhost:8000";
  else return `https://${window.location.hostname}:8000`;
}

export function siteDomainName() {
  if (window.location.hostname === "localhost") return "http://localhost:3000/";
  else return `https://${window.location.hostname}/`;
}

export function websocketDomainName() {
  if (window.location.hostname === "localhost")
    return "ws://localhost/websocket/";
  else return `wss://${window.location.hostname}/websocket/`;
}

export const mediaPath = "";
// export const BASE_API_URL = `https://api.${window.location.hostname}/api`;
// export const BASE_API_URL = `https://mainsias.herokuapp.com/api`;
// export const BASE_API_URL=`https://e347-2409-4042-4d41-6f11-d86a-5fae-34b0-ce5f.ngrok.io/api`
export const BASE_API_URL = 'https://back.mainsias.com/api' 
