import { apiPost, authApiPost, apiDelete, authApiGet } from "../utils/axios";
import { BASE_API_URL } from "./domain";

export default {
  authSignUp: (payload) => apiPost(`${BASE_API_URL}/auth/signup`, payload),
  authSignIn: (payload) => apiPost(`${BASE_API_URL}/auth/signin`, payload),
  authOtpVerifyOrResend: (payload) =>
    apiPost(`${BASE_API_URL}/auth/verify/email_verification`, payload),
  authOtpForgoPassword: (payload) =>
    apiPost(`${BASE_API_URL}/auth/verify/forgot-password`, payload),
  updateProfile: (payload, token) =>
    authApiPost(`${BASE_API_URL}/general/update-user-profile`, payload, token),
  changePassword: (payload, token) =>
    authApiPost(`${BASE_API_URL}/auth/change-password`, payload, token),
  deleteUser: (userId, token) =>
    apiDelete(`${BASE_API_URL}/auth/${userId}/delete`, token),
  getCanCreateSubscription: (userId, token) =>
    authApiGet(
      `${BASE_API_URL}/auth/${userId}/get-can_create_subscription`,
      token
    ),
};
