import EvaluationActionTypes from "../actionTypes/evalutaion";

export const fetchEvaluationStart = (token) => ({
  type: EvaluationActionTypes.FETCH_EVALUATION_START,
  payload: token,
});

export const fetchEvaluationSuccess = (evaluations) => ({
  type: EvaluationActionTypes.FETCH_EVALUATION_SUCCESS,
  payload: evaluations,
});

export const fetchEvaluationFailure = () => ({
  type: EvaluationActionTypes.FETCH_EVALUATION_FAILURE,
});

export const clearEvaluationState = () => ({
  type: EvaluationActionTypes.CLEAR_EVALUATION_STATE,
});

export const updateEvaluationStatus = (evaluationId) => ({
  type: EvaluationActionTypes.UPDATE_EVALUATION_STATUS,
  payload: evaluationId,
});
