import { authApiFormDataPost, authApiGet, authApiPost } from "../utils/axios";
import { BASE_API_URL } from "./domain";

export default {
  getEvalutaionList: (token, evalId, query, limit, skip, filterBy) =>
    evalId
      ? authApiGet(
          `${BASE_API_URL}/evaluation/my-evaluations/all?eid=${evalId}&skip=${skip}&limit=${limit}&filter_by=${filterBy}`,
          token
        )
      : query
      ? authApiGet(
          `${BASE_API_URL}/evaluation/my-evaluations/all${query ? query : ""}`,
          token
        )
      : authApiGet(
          `${BASE_API_URL}/evaluation/my-evaluations/all?skip=${skip}&limit=${limit}&filter_by=${filterBy}`,
          token
        ),
  rejectEvluation: (evalId, payload, token) =>
    authApiPost(
      `${BASE_API_URL}/evaluation/${evalId}/reject-answer`,
      payload,
      token
    ),

  asignEvaluator: (payload, evalId, token) =>
    authApiPost(
      `${BASE_API_URL}/evaluation/${evalId}/update-evaluator`,
      payload,
      token
    ),
  getEvalutaionDetails: (evalId, token) =>
    authApiGet(`${BASE_API_URL}/evaluation/${evalId}/details`, token),

  saveEvaluationWork: (evalId, payload, token, setProgress) =>
    authApiFormDataPost(
      `${BASE_API_URL}/evaluation/${evalId}/save-evaluator-work`,
      payload,
      token,
      setProgress
    ),

  saveStudentWork: (evalId, payload, token, setProgress) =>
    authApiFormDataPost(
      `${BASE_API_URL}/evaluation/${evalId}/save-student-work`,
      payload,
      token,
      setProgress
    ),

  getNotice: (token) =>
    authApiGet(`${BASE_API_URL}/general/get-notices`, token),
  getStudentList: (searchString, token) =>
    authApiGet(`${BASE_API_URL}/student/search?q=${searchString}`, token),
  getStudentDetails: (studentId, token) =>
    authApiGet(`${BASE_API_URL}/student/${studentId}/get-full-details`, token),
};
