const ManagerActionType = {
  FETCH_MANAGER_START: "FETCH_MANAGER_START",
  FETCH_MANAGER_SUCCESS: "FETCH_MANAGER_SUCCESS",
  FETCH_MANAGER_FAILURE: "FETCH_MANAGER_FAILURE",
  CREATE_MANAGER_START: "CREATE_MANAGER_START",
  CREATE_MANAGER_SUCCESS: "CREATE_MANAGER_SUCCESS",
  CREATE_MANAGER_FAILURE: "CREATE_MANAGER_FAILURE",
};

export default ManagerActionType;
