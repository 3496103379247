const ExamActionTypes = {
  FETCH_EXAM_START: "FETCH_EXAM_START",
  FETCH_EXAM_SUCCESS: "FETCH_EXAM_SUCCESS",
  FETCH_EXAM_FAILURE: "FETCH_EXAM_FAILURE",
  CREATE_EXAM_START: "CREATE_EXAM_START",
  CREATE_EXAM_SUCCESS: "CREATE_EXAM_SUCCESS",
  CREATE_EXAM_FAILURE: "CREATE_EXAM_FAILURE",
  FETCH_SUBSCRIPTION_START: "FETCH_SUBSCRIPTION_START",
  FETCH_SUBSCRIPTION_SUCCESS: "FETCH_SUBSCRIPTION_SUCCESS",
  FETCH_SUBSCRIPTION_FAILURE: "FETCH_SUBSCRIPTION_FAILURE",
  CREATE_SUBSCRIPTION_START: "CREATE_SUBSCRIPTION_START",
  CREATE_SUBSCRIPTION_SUCCESS: "CREATE_SUBSCRIPTION_SUCCESS",
  CREATE_SUBSCRIPTION_FAILURE: "CREATE_SUBSCRIPTION_FAILURE",
  MANAGER_CREATE_SUBSCRIPTION: "MANAGER_CREATE_SUBSCRIPTION ",
  FETCH_EXAM_DETAILS_START: "FETCH_EXAM_DETAILS_START",
  FETCH_EXAM_DETAILS_SUCCESS: "FETCH_EXAM_DETAILS_SUCCESS",
  FETCH_EXAM_DETAILS_FAILURE: "FETCH_EXAM_DETAILS_FAILURE",
  UPDATE_EXAM_PROFILE_START: "UPDATE_EXAM_PROFILE_START",
  UPDATE_EXAM_PROFILE_FAILURE: "UPDATE_EXAM_PROFILE_FAILURE",
  UPDATE_EXAM_PROFILE_SUCCESS: "UPDATE_EXAM_PROFILE_SUCCESS",
  UPDATE_SUBSCRIPTION_FEATURED_START: "UPDATE_SUBSCRIPTION_FEATURED_START",
  UPDATE_SUBSCRIPTION_FEATURED_SUCCESS: "UPDATE_SUBSCRIPTION_FEATURED_SUCCESS",
  UPDATE_SUBSCRIPTION_FEATURED_FAILURE: "UPDATE_SUBSCRIPTION_FEATURED_FAILURE",
  UPDATE_SUBSCRIPTION_ACTIVE_START: "UPDATE_SUBSCRIPTION_ACTIVE_START",
  UPDATE_SUBSCRIPTION_ACTIVE_SUCCESS: "UPDATE_SUBSCRIPTION_ACTIVE_SUCCESS",
  UPDATE_SUBSCRIPTION_ACTIVE_FAILURE: "UPDATE_SUBSCRIPTION_ACTIVE_FAILURE",
  BUY_SUBSCRIPTION_START: "BUY_SUBSCRIPTION_START",
  BUY_SUBSCRIPTION_SUCCESS: "BUY_SUBSCRIPTION_SUCCESS",
  BUY_SUBSCRIPTION_FAILURE: "BUY_SUBSCRIPTION_FAILURE",
  FETCH_PAID_SUBSCRIPTION_LIST_START: "FETCH_PAID_SUBSCRIPTION_LIST_START",
  FETCH_PAID_SUBSCRIPTION_LIST_SUCCESS: "FETCH_PAID_SUBSCRIPTION_LIST_SUCCESS",
  FETCH_PAID_SUBSCRIPTION_LIST_FAILURE: "FETCH_PAID_SUBSCRIPTION_LIST_FAILURE",
  FETCH_PAID_SUBSCRIPTION_DETAILS_START:
    "FETCH_PAID_SUBSCRIPTION_DETAILS_START",
  FETCH_PAID_SUBSCRIPTION_DETAILS_SUCCESS:
    "FETCH_PAID_SUBSCRIPTION_DETAILS_SUCCESS",
  FETCH_PAID_SUBSCRIPTION_DETAILS_FAILURE:
    "FETCH_PAID_SUBSCRIPTION_DETAILS_FAILURE",
  UPLOAD_QUESTION_START: "UPLOAD_QUESTION_START",
  UPLOAD_QUESTION_SUCCESS: "UPLOAD_QUESTION_SUCCESS",
  UPLOAD_QUESTION_PERCENTAGE: "UPLOAD_QUESTION_PERCENTAGE",
  UPLOAD_QUESTION_FAILURE: "UPLOAD_QUESTION_FAILURE",
  FETCH_PAID_SUBSCRIPTION_ALLOWENCES_START:
    "FETCH_PAID_SUBSCRIPTION_ALLOWENCES_START",
  FETCH_PAID_SUBSCRIPTION_ALLOWENCES_SUCCESS:
    "FETCH_PAID_SUBSCRIPTION_ALLOWENCES_SUCCESS",
  FETCH_PAID_SUBSCRIPTION_ALLOWENCES_FAILURE:
    "FETCH_PAID_SUBSCRIPTION_ALLOWENCES_FAILURE",
  CLEAR_EXAM_STATE: "CLEAR_EXAM_STATE",
  UPDATE_EXAM_SBUSCRIPTION: "UPDATE_EXAM_SBUSCRIPTION ",
  DELETE_EXAM_SUBSCRIPTION: "DELETE_EXAM_SUBSCRIPTION",
  CLEAR_SUBSCRIPTION_DETAILS: "CLEAR_SUBSCRIPTION_DETAILS",
};

export default ExamActionTypes;
