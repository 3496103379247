import { call, all, put, takeLatest } from "redux-saga/effects";
import { toast } from "react-toastify";

import managerApi from "../../services/manager";
import {
  createManagerFailure,
  fetchManagerFailure,
  fetchManagerSuccess,
  createManagerSuccess,
} from "../actions/manager";
import ManagerActionType from "../actionTypes/manager";

//fetch manager saga
export function* fetchMangerAsync({ payload }) {
  try {
    const res = yield call(managerApi.getManagerList, {
      token: payload.token,
    });

    if (res.data.result !== true && res.status === 200) {
      if (res.data.result === "error") toast.error("Something went wrong");
      if (res.data.result === false) toast.error("Unable to Fetch Manager");
      yield put(fetchManagerFailure());
    }

    if (res.data.result === true && res.status === 200) {
      yield put(fetchManagerSuccess(res.data.manager_list));
    }
  } catch (e) {
    toast.error("Something went wrong");
    yield put(fetchManagerFailure());
  }
}

//create manager saga
export function* createManagerAsync({ payload }) {
  try {
    const res = yield call(
      managerApi.createManager,
      {
        manager: {
          first_name: payload.firstName,
          last_name: payload.lastName,
          email: payload.email,
          password: payload.password,
          phone: payload.phone,
        },
        assign_exams: payload.assignExams,
      },
      payload.token
    );

    if (res.data.result === true && res.status === 200) {
      yield put(createManagerSuccess());
    }

    if (res.data.result !== true && res.status === 200) {
      if (res.data.result === "error") toast.error("Something went wrong");
      if (res.data.result === false) toast.error("Unable to Create Manager");
      if (res.data.result === "emailExist") toast.error("Email Already Exist");
      yield put(createManagerFailure());
    }
  } catch (e) {
    toast.error("Something went wrong");
    yield put(createManagerFailure());
  }
}

export function* fetchManager() {
  yield takeLatest(ManagerActionType.FETCH_MANAGER_START, fetchMangerAsync);
}

export function* createManager() {
  yield takeLatest(ManagerActionType.CREATE_MANAGER_START, createManagerAsync);
}

export function* managerSaga() {
  yield all([call(fetchManager), call(createManager)]);
}
