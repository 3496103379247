import { updateEvaluationStatus } from "../utils/evaluation";
import EvaluationActionTypes from "../actionTypes/evalutaion";

const INITIAL_STATE = {
  evalutions: null,
  isLoading: false,
  totalEvaluation: null,
};

const EvalutionReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case EvaluationActionTypes.FETCH_EVALUATION_START:
      return {
        ...state,
        isLoading: true,
      };

    case EvaluationActionTypes.FETCH_EVALUATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        evalutions: action.payload.evaluations,
        totalEvaluation: action.payload.total_evaluations,
      };

    case EvaluationActionTypes.CLEAR_EVALUATION_STATE:
      return {
        ...state,
        evalutions: null,
      };

    case EvaluationActionTypes.UPDATE_EVALUATION_STATUS:
      return {
        ...state,
        evalutions: updateEvaluationStatus(state.evalutions, action.payload),
      };

    case EvaluationActionTypes.FETCH_EVALUATION_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default EvalutionReducer;
