import { call, all, put, takeLatest } from "redux-saga/effects";
import { toast } from "react-toastify";
import {
  createExamFailure,
  createExamSuccess,
  fetchExamFailure,
  fetchExamSuccess,
  createExamSubscriptionFailure,
  createExamSubscriptionSuccess,
  fetchExamSubscriptionSuccess,
  fetchExamSubscriptionFailure,
  fetchExamDetailsFailure,
  fetchExamDetailsSuccess,
  updateExamProfileFailure,
  updateExamProfileSuccess,
  updateSubscriptionFeaturedFailure,
  updateSubscriptionFeaturedSuccess,
  buySubscriptionFailure,
  buySubscriptionSuccess,
  fetchPaidSubscriptionListFailure,
  fetchPaidSubscriptionListSuccess,
  fetchPaidSubscriptiondDetailsFailure,
  fetchPaidSubscriptiondDetailsSuccess,
  uploadQuestionFailure,
  uploadQuestionSuccess,
  fetchPaidSubscriptionAllowencesFailure,
  fetchPaidSubscriptionAllowencesSuccess,
  updateSubscriptionActiveSuccess,
  uploadQuestionPercentage,
  managerCreateExamSubscriptionSuccess,
} from "../actions/exam";
import examApi from "../../services/exam";
import ExamActionTypes from "../actionTypes/exam";

//Fetch Exam Saga
export function* fetchExamAsync({ payload }) {
  try {
    const res = yield call(
      examApi.getExamList,
      {
        token: payload.token,
      },
      payload.isAdmin
    );

    if (res.data.result !== true && res.status === 200) {
      if (res.data.result === "error") toast.error("Something went wrong");
      if (res.data.result === false) toast.error("Unable to Fetch Exams");
      yield put(fetchExamFailure());
    }

    if (res.data.result === true && res.status === 200) {
      yield put(fetchExamSuccess(res.data.exam_list));
    }
  } catch (e) {
    toast.error("Unable to Fetch exam list");
    yield put(fetchExamFailure());
  }
}

//Create Exam Saga
export function* createExamAsync({ payload }) {
  try {
    const res = yield call(
      examApi.createExam,
      {
        short_name: payload.shortName,
        full_name: payload.fullName,
      },
      payload.token
    );

    if (res.data.result !== true && res.status === 200) {
      if (res.data.result === "error") toast.error("Something went wrong");
      if (res.data.result === false) toast.error("short name must be unique");
      yield put(createExamFailure());
    }

    if (res.data.result === true && res.status === 200) {
      yield put(createExamSuccess());
    }
  } catch (e) {
    toast.error("Unable to Create Exam");
    yield put(createExamFailure());
  }
}

//Fetch Exam Subscription Saga
export function* fetchExamSubcriptionAsync({ payload }) {
  try {
    const res = yield call(examApi.getExamSubscriptionList, payload.examId, {
      token: payload.token,
    });

    if (res.data.result !== true && res.status === 200) {
      if (res.data.result === "error") toast.error("Something went wrong");
      if (res.data.result === false) toast.error("Inavlid exam Id");
      yield put(fetchExamSubscriptionFailure());
    }

    if (res.data.result === true && res.status === 200) {
      yield put(fetchExamSubscriptionSuccess(res.data.subscription_list));
    }
  } catch (e) {
    toast.error("Unable to fetch subscription");
    fetchExamSubscriptionFailure();
  }
}

//Create Subscription Saga
export function* createExamSubscriptionAsync({ payload }) {
  try {
    const res = yield call(
      examApi.createExamSubscription,
      payload.subscriptionDetails,
      payload.token
    );
    if (res.data.result !== true && res.status === 200) {
      if (res.data.result === "error") toast.error("Something went wrong");
      if (res.data.result === false)
        toast.error("Unable To Create Subscription");
      yield put(createExamSubscriptionFailure());
    }

    if (res.data.result === true && res.status === 200) {
      if (payload.isAdmin)
        yield put(createExamSubscriptionSuccess(res.data.exam_details));
      else {
        yield put(managerCreateExamSubscriptionSuccess());
      }
    }
  } catch (e) {
    toast.error("Something Went Wrong");
    yield put(createExamSubscriptionFailure());
  }
}

//Fetch Exam Details Saga
export function* fetchExamDetailsAsync({ payload }) {
  try {
    const res = yield call(examApi.getExamDetails, payload.shortname, {
      token: payload.token,
    });

    if (res.data.result !== true && res.status === 200) {
      if (res.data.result === "error") toast.error("Something went wrong");
      if (res.data.result === false)
        toast.error("Unable To Fetch Exam Details");
      yield put(fetchExamDetailsFailure());
    }

    if (res.data.result === true && res.status === 200) {
      yield put(fetchExamDetailsSuccess(res.data.exam_details));
    }
  } catch (e) {
    yield put(fetchExamDetailsFailure());
  }
}

//Update Exam Profile Saga
export function* updateExamProfileAsync({ payload }) {
  try {
    const res = yield call(
      examApi.updateExamProfile,
      {
        exam_details: {
          id: payload.examDetails.examId,
          short_name: payload.examDetails.shortName,
          full_name: payload.examDetails.fullName,
          content1: payload.examDetails.content1,
          content2: payload.examDetails.content2,
          content3: payload.examDetails.content3,
          content4: payload.examDetails.content4,
          active: true,
        },
      },
      payload.shortName,
      payload.token
    );

    if (res.data.result !== true && res.status === 200) {
      if (res.data.result === "error") toast.error("Something went wrong");
      if (res.data.result === false) toast.error("Unable To Update Profile");
      yield put(updateExamProfileFailure());
    }

    if (res.data.result === true && res.status === 200) {
      yield put(updateExamProfileSuccess());
      yield toast.success("Profile Update Successfully!");
    }
  } catch (e) {
    toast.error("Something went wrong");
    yield put(updateExamProfileFailure());
  }
}

//Update Subscription Featured Saga
export function* updateSubscriptionFeaturedAsync({ payload }) {
  try {
    const res = yield call(
      examApi.updateSubscriptionFeatured,
      {
        sid: payload.subscriptionFeatured.sid,
        featured: payload.subscriptionFeatured.featured,
      },
      payload.token
    );

    if (res.data.result !== true && res.status === 200) {
      if (res.data.result === "error") toast.error("Something went wrong");
      if (res.data.result === false) toast.error("Unable To Update Feature");
      yield put(updateSubscriptionFeaturedFailure());
    }

    if (res.data.result === true && res.status === 200) {
      yield put(
        updateSubscriptionFeaturedSuccess(payload.subscriptionFeatured.sid)
      );
      yield toast.success("Success");
    }
  } catch (e) {
    yield put(updateSubscriptionFeaturedFailure());
    toast.error("Something went wrong");
  }
}

//Update Subscription Active Saga
export function* updateSubscriptionActiveAsync({ payload }) {
  try {
    const res = yield call(
      examApi.updateSubscriptionActive,
      {
        sid: payload.subscriptionActive.sid,
        active: payload.subscriptionActive.active,
      },
      payload.token
    );

    if (res.data.result !== true && res.status === 200) {
      if (res.data.result === "error") toast.error("Something went wrong");
      if (res.data.result === false) toast.error("Unable To Update Feature");
      yield put(updateSubscriptionFeaturedFailure());
    }

    if (res.data.result === true && res.status === 200) {
      yield put(
        updateSubscriptionActiveSuccess(payload.subscriptionActive.sid)
      );
      yield toast.success("Success");
    }
  } catch (e) {
    yield put(updateSubscriptionFeaturedFailure());
    toast.error("Something went wrong");
  }
}

//Student Buy Subsciption Saga
export function* buySubscriptionAync({ payload }) {
  try {
    const res = yield call(
      examApi.buySubcription,
      {
        subscription: {
          id: payload.subscription.id,
          subscription_allowences: payload.subscription.subscription_allowences,
          name: payload.subscription.name,
          days: payload.subscription.days,
          till_date: payload.subscription.till_date,
          price: payload.subscription.price,
          active: payload.subscription.active,
          featured: payload.subscription.featured,
          selling_points: payload.subscription.selling_points,
          exam: payload.examId,
        },
        order_id: payload.order.orderId,
        signature: payload.order.signature,
        payment_id: payload.order.paymentId,
      },
      payload.token
    );

    if (res.data.result !== true && res.status === 200) {
      if (res.data.result === "error") toast.error("Something went wrong");
      if (res.data.result === false) toast.error("Unable To Buy Subscription");
      yield put(buySubscriptionFailure());
    }

    if (res.data.result === true && res.status === 200) {
      yield put(buySubscriptionSuccess());
      yield toast.success("Subscribed Subscription Successfull");
    }
  } catch (e) {
    yield put(buySubscriptionFailure());
    yield toast.error("Something went wrong");
  }
}

//Fetch Student Paid Subscription List
export function* fetchPiadSubscriptionListAsync({ payload }) {
  try {
    const res = yield call(
      examApi.getPaidSubscriptionList,
      {
        token: payload.token,
      },
      payload.query
    );

    if (res.data.result !== true && res.status === 200) {
      if (res.data.result === "error") toast.error("Something went wrong");
      if (res.data.result === false)
        toast.error("Unable To Fetch Subscription");
      yield put(fetchPaidSubscriptionListFailure());
    }

    if (res.data.result === true && res.status === 200) {
      yield put(fetchPaidSubscriptionListSuccess(res.data.subscribed_record));
    }
  } catch (e) {
    yield put(fetchPaidSubscriptionListFailure());
    yield toast.error("Something Went Wrong");
  }
}

//Fetch Student Subscription Details
export function* fetchPiadSubscriptionDetailsAsync({ payload }) {
  try {
    const res = yield call(
      examApi.getPaidSubscriptionDetails,
      payload.shortname,
      {
        token: payload.token,
      }
    );

    if (res.data.result !== true && res.status === 200) {
      if (res.data.result === "error") toast.error("Something went wrong");
      if (res.data.result === false)
        toast.error("Unable To Fetch Subscription Details");
      yield put(fetchPaidSubscriptiondDetailsFailure());
    }

    if (res.data.result === true && res.status === 200) {
      yield put(fetchPaidSubscriptiondDetailsSuccess(res.data));
    }
  } catch (e) {
    yield put(fetchPaidSubscriptiondDetailsFailure());
    yield toast.error("Something went wrong");
  }
}

//Upload Student Questions
//Not In Use
export function* uploadQuestionAsync({ payload }) {
  try {
    let percent;
    const res = yield call(
      examApi.uploadQuestion,
      payload.details,
      payload.token,
      percent
    );

    // yield put(uploadQuestionPercentage({ show: true, percentage: percent }));

    if (res.data.result !== true && res.status === 200) {
      if (res.data.result === "error") toast.error("Something went wrong");
      if (res.data.result === false) toast.error("Unable To Submit Question");
      yield put(uploadQuestionFailure());
    }

    if (res.data.result === true && res.status === 200) {
      yield put(uploadQuestionSuccess(res.data));
    }
  } catch (e) {
    yield put(uploadQuestionFailure());
    toast.error("Something went wrong");
  }
}

//fetch Student paid subscription Allowneces
export function* fetchPaidSubscriptionAllowencesAsync({ payload }) {
  try {
    const res = yield call(examApi.getPaidAllowences, payload.sid, {
      token: payload.token,
    });

    if (res.data.result !== true && res.status === 200) {
      if (res.data.result === "error") toast.error("Something went wrong");
      if (res.data.result === false) toast.error("Unable To Fetch Allowences");
      yield put(fetchPaidSubscriptionAllowencesFailure());
    }

    if (res.data.result === true && res.status === 200) {
      yield put(
        fetchPaidSubscriptionAllowencesSuccess(res.data.updated_allowences)
      );
    }
  } catch (e) {
    yield put(fetchPaidSubscriptionAllowencesFailure());
    toast.error("Something went wrong");
  }
}

export function* fetchExam() {
  yield takeLatest(ExamActionTypes.FETCH_EXAM_START, fetchExamAsync);
}

export function* createExam() {
  yield takeLatest(ExamActionTypes.CREATE_EXAM_START, createExamAsync);
}

export function* fetchExamSubcription() {
  yield takeLatest(
    ExamActionTypes.FETCH_SUBSCRIPTION_START,
    fetchExamSubcriptionAsync
  );
}

export function* createExamSubscription() {
  yield takeLatest(
    ExamActionTypes.CREATE_SUBSCRIPTION_START,
    createExamSubscriptionAsync
  );
}

export function* fetchExamDetails() {
  yield takeLatest(
    ExamActionTypes.FETCH_EXAM_DETAILS_START,
    fetchExamDetailsAsync
  );
}

export function* updateExamProfile() {
  yield takeLatest(
    ExamActionTypes.UPDATE_EXAM_PROFILE_START,
    updateExamProfileAsync
  );
}

export function* updateSubscriptionFeatured() {
  yield takeLatest(
    ExamActionTypes.UPDATE_SUBSCRIPTION_FEATURED_START,
    updateSubscriptionFeaturedAsync
  );
}

export function* updateSubscriptionActive() {
  yield takeLatest(
    ExamActionTypes.UPDATE_SUBSCRIPTION_ACTIVE_START,
    updateSubscriptionActiveAsync
  );
}

export function* buySubscription() {
  yield takeLatest(ExamActionTypes.BUY_SUBSCRIPTION_START, buySubscriptionAync);
}

export function* fetchPiadSubscriptionList() {
  yield takeLatest(
    ExamActionTypes.FETCH_PAID_SUBSCRIPTION_LIST_START,
    fetchPiadSubscriptionListAsync
  );
}

export function* fetchPiadSubscriptionDetails() {
  yield takeLatest(
    ExamActionTypes.FETCH_PAID_SUBSCRIPTION_DETAILS_START,
    fetchPiadSubscriptionDetailsAsync
  );
}

export function* uploadQuestion() {
  yield takeLatest(ExamActionTypes.UPLOAD_QUESTION_START, uploadQuestionAsync);
}

export function* fetchPaidSubscriptionAllowences() {
  yield takeLatest(
    ExamActionTypes.FETCH_PAID_SUBSCRIPTION_ALLOWENCES_START,
    fetchPaidSubscriptionAllowencesAsync
  );
}

export function* examSaga() {
  yield all([
    call(fetchExam),
    call(createExam),
    call(fetchExamSubcription),
    call(createExamSubscription),
    call(fetchExamDetails),
    call(updateExamProfile),
    call(updateSubscriptionFeatured),
    call(updateSubscriptionActive),
    call(buySubscription),
    call(fetchPiadSubscriptionList),
    call(fetchPiadSubscriptionDetails),
    call(uploadQuestion),
    call(fetchPaidSubscriptionAllowences),
  ]);
}
