import axios from "axios";

export const apiGet = (path) => {
    const config = {
        headers: {
            "Content-Type": "application/json",
        },
    };

    return axios.get(path, config);
};

export const authApiGet = (path, token) => {
    const config = {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token.token}`,
        },
    };

    return axios.get(path, config);
};

export const apiPost = (path, data) => {
    const config = {
        headers: {
            "Content-Type": "application/json",
        },
    };
    console.log(path)
    return axios.post(path, data, config);
};

export const authApiPost = (path, data, token) => {
    const config = {
        headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
        },
    };

    return axios.post(path, data, config);
};

export const authApiFormDataPost = (path, data, token, setProgress) => {
    const config = {
        headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent) => {
            const { loaded, total } = progressEvent;
            let percent = Math.floor((loaded * 100) / total);

            if (percent < 100) {
                setProgress({ show: true, percentage: percent });
            }
        },
    };

    return axios.post(path, data, config);
};

export const apiDelete = (path, token) => {
    const config = {
        headers: {
            Authorization: `Token ${token}`,
        },
    };

    return axios.delete(path, {...config });
};