import { all, call } from "redux-saga/effects";
import { authSaga } from "./sagas/auth";
import { examSaga } from "./sagas/exam";
import { managerSaga } from "./sagas/manager";
import { teacherSaga } from "./sagas/teacher";
import { evaluationSaga } from "./sagas/evaluation";

export default function* rootSaga() {
  yield all([
    call(authSaga),
    call(managerSaga),
    call(examSaga),
    call(teacherSaga),
    call(evaluationSaga),
  ]);
}
