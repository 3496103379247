import AuthActionTypes from "../actionTypes/auth";

export const signUpStart = (userCredentials) => ({
  type: AuthActionTypes.AUTH_SIGN_UP_START,
  payload: userCredentials,
});

export const signUpSuccess = (userCrendtials) => ({
  type: AuthActionTypes.AUTH_SIGN_UP_SUCCESS,
  payload: userCrendtials,
});

export const signUpFailure = () => ({
  type: AuthActionTypes.AUTH_SIGN_UP_FAILURE,
});

export const signInStart = (userCredentials) => ({
  type: AuthActionTypes.AUTH_SIGN_IN_START,
  payload: userCredentials,
});

export const signInSuccess = (userCredentials) => ({
  type: AuthActionTypes.AUTH_SIGN_IN_SUCCESS,
  payload: userCredentials,
});

export const signInFailure = () => ({
  type: AuthActionTypes.AUTH_SIGN_IN_FAILURE,
});

export const logoutStart = () => ({
  type: AuthActionTypes.AUTH_LOGOUT_START,
});

export const logoutSuccess = () => ({
  type: AuthActionTypes.AUTH_LOGOUT_SUCCESS,
});

export const logoutFailure = () => ({
  type: AuthActionTypes.AUTH_LOGOUT_FAILURE,
});

export const otpVerifyStart = (token) => ({
  type: AuthActionTypes.OTP_VERIFY_START,
  payload: token,
});

export const otpVerifySuccess = (result) => ({
  type: AuthActionTypes.OTP_VERIFY_SUCCESS,
  payload: result,
});

export const otpVerifyFailure = () => ({
  type: AuthActionTypes.OTP_VERIFY_FAILURE,
});

export const otpResendStart = (token) => ({
  type: AuthActionTypes.OTP_RESEND_START,
  payload: token,
});

export const otpResendSuccess = () => ({
  type: AuthActionTypes.OTP_RESEND_SUCCESS,
});

export const otpResendFailure = () => ({
  type: AuthActionTypes.OTP_RESEND_FAILURE,
});

export const updateProfileStart = (profileDetails) => ({
  type: AuthActionTypes.UPDATE_PROFILE_START,
  payload: profileDetails,
});

export const updateProfileSuccess = (currentUser) => ({
  type: AuthActionTypes.UPDATE_PROFILE_SUCCESS,
  payload: currentUser,
});

export const updateProfileFailure = () => ({
  type: AuthActionTypes.UPDATE_PROFILE_FAILURE,
});

export const userDeleteStart = (idAndToken) => ({
  type: AuthActionTypes.USER_DELETE_START,
  payload: idAndToken,
});

export const userDeleteSuccess = () => ({
  type: AuthActionTypes.USER_DELETE_SUCCESS,
});

export const userDeleteFailure = () => ({
  type: AuthActionTypes.USER_DELETE_FAILURE,
});

export const userDeleteRedirect = () => ({
  type: AuthActionTypes.USER_DELETE_REDIRECT,
});

export const getCanCreateSubscription = (canCreateSubscription) => ({
  type: AuthActionTypes.GET_CAN_CREATE_SUBSCRIPTION,
  payload: canCreateSubscription,
});
