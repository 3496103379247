const AuthActionTypes = {
  SET_CURRENT_USER: "SET_CURRENT_USER ",
  AUTH_SIGN_UP_START: "AUTH_SIGN_UP_START",
  AUTH_SIGN_UP_SUCCESS: "AUTH_SIGN_UP_SUCCESS",
  AUTH_SIGN_UP_FAILURE: "AUTH_SIGN_UP_FAILURE",
  AUTH_SIGN_IN_START: "AUTH_SIGN_IN_START",
  AUTH_SIGN_IN_SUCCESS: "AUTH_SIGN_IN_SUCCESS",
  AUTH_SIGN_IN_FAILURE: "AUTH_SIGN_IN_FAILURE",
  AUTH_LOGOUT_START: "AUTH_LOGOUT_START",
  AUTH_LOGOUT_SUCCESS: "AUTH_LOGOUT_SUCCESS",
  AUTH_LOGOUT_FAILURE: "AUTH_LOGOUT_SUCCESS",
  OTP_VERIFY_START: "OTP_VERIFY_START",
  OTP_VERIFY_SUCCESS: "OTP_VERIFY_SUCCESS",
  OTP_VERIFY_FAILURE: "OTP_VERIFY_FAILURE",
  OTP_RESEND_START: "OTP_RESEND_START",
  OTP_RESEND_SUCCESS: "OTP_RESEND_SUCCESS",
  OTP_RESEND_FAILURE: "OTP_RESEND_FAILURE",
  UPDATE_PROFILE_START: "UPDATE_PROFILE_START",
  UPDATE_PROFILE_SUCCESS: "UPDATE_PROFILE_SUCCESS",
  UPDATE_PROFILE_FAILURE: "UPDATE_PROFILE_FAILURE",
  USER_DELETE_START: "USER_DELETE_START",
  USER_DELETE_SUCCESS: "USER_DELETE_SUCCESS",
  USER_DELETE_FAILURE: "USER_DELETE_FAILURE",
  USER_DELETE_REDIRECT: "USER_DELETE_REDIRECT ",
  GET_CAN_CREATE_SUBSCRIPTION: "GET_CAN_CREATE_SUBSCRIPTION ",
};

export default AuthActionTypes;
