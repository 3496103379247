import ManagerActionType from "../actionTypes/manager";

export const fetchManagerStart = (token) => ({
  type: ManagerActionType.FETCH_MANAGER_START,
  payload: token,
});

export const fetchManagerSuccess = (managerList) => ({
  type: ManagerActionType.FETCH_MANAGER_SUCCESS,
  payload: managerList,
});

export const fetchManagerFailure = () => ({
  type: ManagerActionType.FETCH_MANAGER_FAILURE,
});

export const createManagerStart = (managerDetails) => ({
  type: ManagerActionType.CREATE_MANAGER_START,
  payload: managerDetails,
});

export const createManagerSuccess = () => ({
  type: ManagerActionType.CREATE_MANAGER_SUCCESS,
});

export const createManagerFailure = () => ({
  type: ManagerActionType.CREATE_MANAGER_FAILURE,
});
