export const updateEvaluationStatus = (evaluations, result) => {
  let index = evaluations.findIndex(
    (evaluation) => evaluation.id == result.evaluation_id
  );

  if (result.evaluator_message)
    evaluations[index].evaluator_message = result.evaluator_message;

  if (result.marks) evaluations[index].marks = result.marks;
  if (result.tags) evaluations[index].tags = result.tags;
  if (result.evaluated_file)
    evaluations[index].evaluated_file = result.evaluated_file;

  evaluations[index].status = result.evaluation_status;
  return evaluations;
};
