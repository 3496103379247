const TeacherActionTypes = {
  FETCH_TEACHER_START: "FETCH_TEACHER_START",
  FETCH_TEACHER_SUCCESS: "FETCH_TEACHER_SUCCESS",
  FETCH_TEACHER_FAILURE: "FETCH_TEACHER_FAILURE",
  CREATE_TEACHER_START: "CREATE_TEACHER_START",
  CREATE_TEACHER_SUCCESS: "CREATE_TEACHER_SUCCESS",
  CREATE_TEACHER_FAILURE: "CREATE_TEACHER_FAILURE",
};

export default TeacherActionTypes;
