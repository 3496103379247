import ManagerActionType from "../actionTypes/manager";

const INITIAL_STATE = {
  managerList: null,
  isLoading: false,
  isRedirect: false,
};

const managerReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ManagerActionType.FETCH_MANAGER_START:
    case ManagerActionType.CREATE_MANAGER_START:
      return {
        ...state,
        isLoading: true,
        isRedirect: false,
      };

    case ManagerActionType.FETCH_MANAGER_SUCCESS:
      return {
        ...state,
        managerList: action.payload,
        isLoading: false,
      };

    case ManagerActionType.CREATE_MANAGER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isRedirect: true,
      };

    case ManagerActionType.FETCH_MANAGER_FAILURE:
    case ManagerActionType.CREATE_MANAGER_FAILURE:
      return {
        ...state,
        isLoading: false,
      };

    default:
      return state;
  }
};

export default managerReducer;
