import { call, all, put, takeLatest } from "redux-saga/effects";
import { toast } from "react-toastify";

import {
  createTeacherFailure,
  createTeacherSuccess,
  fetchTeacherFailure,
  fetchTeacherSuccess,
} from "../actions/teacher";
import teacherApi from "../../services/teacher";
import TeacherActionTypes from "../actionTypes/teacher";

//fetch teacher saga
export function* fetchTeacherAsync({ payload }) {
  try {
    const res = yield call(teacherApi.getTeacher, { token: payload.token });
    if (res.data.result !== true && res.status === 200) {
      if (res.data.result === "error") toast.error("Something went wrong");
      if (res.data.result === false) toast.error("Unable to Teacher Manager");
      yield put(fetchTeacherFailure());
    }

    if (res.data.result === true && res.status === 200) {
      yield put(fetchTeacherSuccess(res.data.teacher_list));
    }
  } catch (e) {
    yield put(fetchTeacherFailure());
  }
}

//create teacher saga
export function* createTeacherAsync({ payload }) {
  try {
    const res = yield call(
      teacherApi.createTeacher,
      {
        first_name: payload.firstName,
        last_name: payload.lastName,
        email: payload.email,
        password: payload.password,
        phone: payload.phone,
      },
      payload.token
    );

    if (res.data.result !== true && res.status === 200) {
      if (res.data.result === "error") toast.error("Something went wrong");
      if (res.data.result === false) toast.error("Unable to Create Evaluator");
      if (res.data.result === "emailExist") toast.error("Email Already Exist");
      yield put(createTeacherFailure());
    }

    if (res.data.result === true && res.status === 200) {
      yield put(createTeacherSuccess());
    }
  } catch (e) {
    yield put(createTeacherFailure());
  }
}

export function* createTeacher() {
  yield takeLatest(TeacherActionTypes.CREATE_TEACHER_START, createTeacherAsync);
}

export function* fetchTeacher() {
  yield takeLatest(TeacherActionTypes.FETCH_TEACHER_START, fetchTeacherAsync);
}

export function* teacherSaga() {
  yield all([call(fetchTeacher), call(createTeacher)]);
}
