import { takeLatest, call, put, all } from "redux-saga/effects";
import { toast } from "react-toastify";

import authApi from "../../services/auth";
import {
  signUpSuccess,
  signUpFailure,
  signInFailure,
  signInSuccess,
  otpVerifyFailure,
  otpVerifySuccess,
  otpResendFailure,
  otpResendSuccess,
  logoutFailure,
  logoutSuccess,
  updateProfileFailure,
  updateProfileSuccess,
  userDeleteSuccess,
  userDeleteFailure,
} from "../actions/auth";
import AuthActionTypes from "../actionTypes/auth";

//Signup Saga
export function* authSignUpAsync({ payload }) {
  try {
    const res = yield call(authApi.authSignUp, {
      first_name: payload.firstName,
      last_name: payload.lastName,
      email: payload.email,
      password: payload.password,
    });

    if (res.data.result !== true && res.status === 200) {
      if (res.data.result === "error") toast.error("Something went wrong");
      if (res.data.result === "exist") toast.error("Email Already registerd");
      yield put(signUpFailure());
    }

    if (res.data.result === true && res.status === 200) {
      yield put(signUpSuccess(res.data));
    }
  } catch (e) {
    toast.error("Something went wrong");
    yield put(signUpFailure());
  }
}

//SignIn Saga
export function* authSignInAsync({ payload }) {
  try {
    const res = yield call(authApi.authSignIn, {
      username: payload.email,
      password: payload.password,
    });

    if (res.data.result !== true && res.status === 200) {
      if (res.data.result === "error") toast.error("Something went wrong");
      if (res.data.result === "invalid") toast.error("Invalid Password");
      if (res.data.result === "nouser") toast.error("Email not Registerd");
      yield put(signInFailure());
    }

    if (res.data.result === true && res.status === 200) {
      // toast.success("Sign In Success");
      if(res.data?.user?.isTeacher)
      yield put(signInSuccess(res.data));
      else {
        toast.error("Email not Registered")
        yield put(signInFailure())
      }
    }
  } catch (e) {
    toast.error("Something went Wrong");
    yield put(signInFailure());
  }
}

//Logout Saga
export function* authLogoutAsync() {
  try {
    yield put(logoutSuccess());
  } catch (e) {
    toast.error("Something went wrong");
    yield put(logoutFailure());
  }
}

//Otp Verifiction saga
export function* authOtpVerifyAsync({ payload }) {
  try {
    const res = yield call(authApi.authOtpVerifyOrResend, {
      otp: payload.otp,
      token: payload.currentUser.token,
    });

    if (res.data.result !== true && res.status === 200) {
      if (res.data.result === "error") toast.error("Something went wrong");
      if (res.data.result === false) toast.error("Invalid Otp");
      yield put(otpVerifyFailure());
    }

    if (res.data.result === true && res.status === 200) {
      // payload.currentUser.user.isVerified = res.data.result;
      yield put(otpVerifySuccess(res.data.result));
    }
  } catch (e) {
    yield otpVerifyFailure();
    toast.error("Something went wrong");
  }
}

//Otp Resend Saga
export function* authOtpResendAsync({ payload }) {
  try {
    const res = yield call(authApi.authOtpVerifyOrResend, {
      do: "resend",
      token: payload.token,
    });

    if (res.data.result !== true && res.status === 200) {
      if (res.data.result === "error") toast.error("Something went wrong");
      if (res.data.result === false) toast.error("Please Resend again");
      yield put(otpResendFailure());
    }
    if (res.data.result === true && res.status === 200) {
      toast.success("otp successfully send");
      yield put(otpResendSuccess());
    }
  } catch (e) {
    toast.error("Something went wrong");
    yield put(otpResendFailure());
  }
}

//Update Profile
export function* updateProfileStartAsync({ payload }) {
  try {
    const res = yield authApi.updateProfile(payload.profile, payload.token);
    if (res.data.result !== true && res.status === 200) {
      if (res.data.result === "error") toast.error("something went wrong");
      if (res.data.result === false) toast.error("Unable to update profile");
      yield put(updateProfileFailure());
    }

    if (res.data.result === true && res.data.result === true) {
      yield put(updateProfileSuccess(res.data));
      toast.success("Success");
    }
  } catch (e) {
    toast.error("Something went wrong");
    yield put(updateProfileFailure());
  }
}

//Delete user saga
export function* userDeleteStartAsync({ payload }) {
  try {
    const res = yield authApi.deleteUser(payload.userId, payload.token);

    if (res.data.result !== true && res.status === 200) {
      if (res.data.result === false)
        return toast.error("Unable to delete user");
      if (res.data.result) return toast.error("Something went wrong");
      yield put(userDeleteFailure());
    }

    if (res.data.result === true && res.status === 200) {
      yield put(userDeleteSuccess());
    }
  } catch (e) {
    yield put(userDeleteFailure());

    toast.error("Something went wrong");
  }
}

export function* authSignUpStart() {
  yield takeLatest(AuthActionTypes.AUTH_SIGN_UP_START, authSignUpAsync);
}

export function* authSignInStart() {
  yield takeLatest(AuthActionTypes.AUTH_SIGN_IN_START, authSignInAsync);
}

export function* authLogout() {
  yield takeLatest(AuthActionTypes.AUTH_LOGOUT_START, authLogoutAsync);
}

export function* authOtpVerifyStart() {
  yield takeLatest(AuthActionTypes.OTP_VERIFY_START, authOtpVerifyAsync);
}

export function* authOtpResend() {
  yield takeLatest(AuthActionTypes.OTP_RESEND_START, authOtpResendAsync);
}

export function* updateProfileStart() {
  yield takeLatest(
    AuthActionTypes.UPDATE_PROFILE_START,
    updateProfileStartAsync
  );
}

export function* userDeleteStart() {
  yield takeLatest(AuthActionTypes.USER_DELETE_START, userDeleteStartAsync);
}

export function* authSaga() {
  yield all([
    call(authSignUpStart),
    call(authSignInStart),
    call(authLogout),
    call(authOtpVerifyStart),
    call(authOtpResend),
    call(updateProfileStart),
    call(userDeleteStart),
  ]);
}
