import { authApiGet, authApiPost } from "../utils/axios";
import { BASE_API_URL } from "./domain";

export default {
  getTeacher: (token) => authApiGet(`${BASE_API_URL}/teacher/get-list`, token),
  createTeacher: (payload, token) =>
    authApiPost(`${BASE_API_URL}/teacher/create`, payload, token),
  getIndivisualTeacher: (token, teacherId) =>
    authApiGet(`${BASE_API_URL}/teacher/${teacherId}/details`, token),
  paymentDetails: (payload, token) =>
    authApiPost(
      `${BASE_API_URL}/teacher/update-payment-details`,
      payload,
      token
    ),
  getPaymentDetails: (teacherId, token) =>
    authApiGet(
      `${BASE_API_URL}/teacher/${teacherId}/get-payment-details`,
      token
    ),
  getGlance: (teacherId, token) =>
    authApiGet(`${BASE_API_URL}/teacher/${teacherId}/get-glance`, token),
  getPreviousGlance: (teacherId, token) =>
    authApiGet(
      `${BASE_API_URL}/teacher/${teacherId}/get-previous-glance`,
      token
    ),
  closeGlanceSession: (payload, teacherId, token) =>
    authApiPost(
      `${BASE_API_URL}/teacher/${teacherId}/close-glance`,
      payload,
      token
    ),

  getSummary: (teacherId, token) =>
    authApiGet(`${BASE_API_URL}/teacher/${teacherId}/get-summary`, token),
  getTotalSummary:(teacherId, token) =>
  authApiGet(`${BASE_API_URL}/teacher/${teacherId}/get-previous-summary`, token),
};
