const path = {
  INDEX: "/",
  INDEX_NAME: "/:id",
  SIGN_IN: "/auth/sign-in",
  SIGN_UP: "/auth/sign-up",
  FORGOT_PASSWORD: "/auth/forgot-password",
  STUDENT_PROFILE: "/student/profile",
  TEACHER_PROFILE: "/evaluator/profile",
  ADMIN_PROFILE: "/admin/profile",
  MANAGER_PROFILE: "/manager/profile",
  DASHBOARD: "/dashboard",
  ADMIN_DASHBOARD: "/admin/dashboard",
  OTP: "/auth/otp",
  MANAGER: "/admin/manager",
  CREATE_MANAGER: "/admin/manager/create-manager",
  EDIT_MANAGER: "/admin/manager/:id/edit",
  TEACHER: "/admin/evaluator",
  CREATE_TEACHER: "/admin/evaluator/create-evaluator",
  EXAM: "/admin/exam",
  CREATE_EXAM: "/admin/exam/create-exam",
  EXAM_DETAILS: "/admin/exam/details/:shortname/:id",
  MANAGER_DASHBOARD: "/manager/dashboard",
  MANAGER_EXAM: "/manager/exam",
  MANAGER_CREATE_EXAM: "/manager/exam/create-exam",
  MANAGER_EXAM_DETAILS: "/manager/exam/details/:shortname/:id",
  STUDENT_DASHBOARD: "/student/dashboard",
  STUDENT_EXAM_LIST: "/student/exam",
  STUDNET_EXAM_DETAILS: "/student/exam/details/:shortname",
  STUDENT_PAID_SUBSCRIPTION_DETAILS:
    "/student/paid/exam/:shortname/subscription/:id",
  STUDENT_SUBSCRIPTIONS: "/student/my-subscription",
  STUDENT_ANSWERS: "/student/my-answer",
  STUDENT_ANSWERS_DETAILS: "/student/my-answer/:id/details",
  TEACHER_DASHBOARD: "/evaluator/dashboard",
  TEACHER_GLANCE: "/evaluator/glance",
  TEACHER_SUMMARY: "/evaluator/summary",
  REQUEST: "/admin/request",
  ADMIN_STUDENT_INFO: "/admin/student-info/:id",
  ADMIN_STUDENT_SEARCH: "/admin/student/find",
  TERM_N_CONDITIONS: "/user/tnc",
  PDF_EDITOR: "/evaluator/pdfeditor",
};

export default path;
