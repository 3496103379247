import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import auth from "./reducers/auth.js";
import manager from "./reducers/manager";
import exam from "./reducers/exam";
import teacher from "./reducers/teacher";
import sidebar from "./reducers/sidebar";
import evaluation from "./reducers/evalutaion";
import authPath from "./reducers/authPath";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth", "sidebar"],
};

const rootReducer = combineReducers({
  auth,
  manager,
  exam,
  teacher,
  sidebar,
  evaluation,
  authPath,
});

export default persistReducer(persistConfig, rootReducer);
